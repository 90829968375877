import React from 'react'
import Footer from "../../Footer";

const NotFound = () => {
    return (
        <div className='Page404'>
            <h1>Awww...Don't Cry</h1>
            <h2>It's just a 404 Error!</h2>
            <Footer/>

        </div>
    )
}

export default NotFound