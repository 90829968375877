import React from "react";
import Main from "./Main";

export default function Home() {
  return (
    <>
      <div style={{ maxWidth: "100vw" }}>
        <div style={{ minHeight: "90vh" }}>
          <Main />
        </div>
      </div>
    </>
  );
}
